<template>
  <div class="vv">
    <template v-if="loading">
      <div>
        <van-sticky>
          <div class="vv-head">
            <mvideo v-if="playState" :cur="curr"></mvideo>
            <div v-else>
              <van-image v-if="book.videoCoverImg" :src="book.videoCoverImg" />
              <van-image
                v-else
                src="https://res.cdn.quyixian.com/video_cover/banner.jpg"
              />
            </div>
          </div>
        </van-sticky>
      </div>
      <div class="vv-ttt" v-if="book.slogan">
        <p>{{ book.slogan }}</p>
      </div>
      <div v-if="book.videoFolderShow == 0" class="vv-list">
        <!--第一层目录-->
        <van-collapse
          :border="false"
          v-model="listNames"
          accordion
          v-if="book.folderList.length > 0"
        >
          <van-collapse-item
            :border="false"
            :title="item.folderName"
            :name="index"
            :data-index="index"
            class="vv-folder"
            v-for="(item, index) in book.folderList"
            :key="item.id"
          >
            <!--第二层目录-->
            <van-collapse
              v-model="itemNames"
              accordion
              v-if="item.childrenCount > 0 && item.childrenList.length > 0"
            >
              <van-collapse-item
                class="vv-folder-item"
                v-for="(j, i) in item.childrenList"
                :key="j.id"
                :name="i"
                :title="j.folderName"
              >
                <!--第三层目录-->
                <van-collapse
                  v-model="itemsNames"
                  accordion
                  v-if="j.childrenCount > 0 && j.childrenList.length > 0"
                >
                  <van-collapse-item
                    class="vv-folder-item vv-folder-item1"
                    style="font-size: 0.24rem"
                    v-for="(k, ii) in j.childrenList"
                    :key="k.id"
                    :name="ii"
                    :title="k.folderName"
                  >
                    <!-- 三级下直接资源 -->
                    <div class="vv-reslist" v-if="k.resList.length > 0">
                      <div
                        class="vv-reslist-item"
                        v-for="res in k.resList"
                        :key="res.rid"
                        @click="
                          playerCurr(
                            res.rid,
                            res.bid,
                            res.fid,
                            res.filePath,
                            item.folderName +
                              '>' +
                              j.folderName +
                              '>' +
                              k.folderName +
                              '>' +
                              res.fileName,
                            res.levels
                          )
                        "
                      >
                        <!-- 二层目录下资源 -->
                        <van-icon
                          v-if="curr.id == res.rid"
                          name="pause"
                        ></van-icon>
                        <van-icon v-else name="play"></van-icon>
                        <p :class="curr.id == res.rid ? 'cur' : ''">
                          {{ res.fileName }}
                        </p>
                      </div>
                    </div>
                  </van-collapse-item>
                </van-collapse>
                <!-- 二级下直接资源 -->
                <div class="vv-reslist" v-else-if="j.resList.length > 0">
                  <div
                    class="vv-reslist-item"
                    v-for="res in j.resList"
                    :key="res.rid"
                    @click="
                      playerCurr(
                        res.rid,
                        res.bid,
                        res.fid,
                        res.filePath,
                        item.folderName +
                          '>' +
                          j.folderName +
                          '>' +
                          res.fileName,
                        res.levels
                      )
                    "
                  >
                    <!-- 二层目录下资源 -->
                    <van-icon v-if="curr.id == res.rid" name="pause"></van-icon>
                    <van-icon v-else name="play"></van-icon>
                    <p :class="curr.id == res.rid ? 'cur' : ''">
                      {{ res.fileName }}
                    </p>
                  </div>
                </div>
              </van-collapse-item>
            </van-collapse>
            <!-- 一层目录下资源 -->
            <div class="vv-reslist" v-else-if="item.resList.length > 0">
              <div
                class="vv-reslist-item"
                v-for="res in item.resList"
                :key="res.rid"
                @click="
                  playerCurr(
                    res.rid,
                    res.bid,
                    res.fid,
                    res.filePath,
                    item.folderName + '>' + res.fileName,
                    res.levels
                  )
                "
              >
                <van-icon v-if="curr.id == res.rid" name="pause"></van-icon>
                <van-icon v-else name="play"></van-icon>
                <p :class="curr.id == res.rid ? 'cur' : ''">
                  {{ res.fileName }}
                </p>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <p v-else class="vv-error-list">出错啦！没有找到列表</p>
      </div>
    </template>
    <template v-else>
      <van-loading class="vv-error" type="spinner" color="#FFA300">{{
        loadingText
      }}</van-loading>
    </template>
    <van-popup round v-model="cardShow" :close-on-click-overlay="false">
      <div class="vv-cardPop">
        <p class="vv-cardPop-title">请添加专属学习顾问</p>
        <div class="vv-cardPop-imgbg">
          <div>
            <van-image :src="card"></van-image>
          </div>
        </div>
        <p class="vv-cardPop-text">长按识别添加，仅首次登录需授权</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
import wx from "../assets/js/wx";
import mvideo from "@/components/mvideo.vue";

export default {
  name: "vbook",
  components: { mvideo },
  data() {
    return {
      listNames: 0,
      itemNames: 0,
      itemsNames: 0,
      bid: this.$route.query.bid,
      code: this.$route.query.code,
      state: this.$route.query.state,
      loading: false,
      loadingText: "解析中...",
      loadingExce: false,
      book: {},
      userid: "",
      userPower: true, //true已授权 false未授权
      card: "https://res.cdn.quyixian.com/video_cover/card.png", //名片地址
      cardShow: false, //是否显示名片
      baseHref: window.location.href, //当前页面URL
      baseUrl:
        window.location.href.split("?")[0] + "?bid=" + this.$route.query.bid, //原始的地址
      playState: false, //弹出视频播放器
      // videoLevels: [
      //   { seconds: '05', title: '第一小节', state: false },
      //   { seconds: '15', title: '第二小节', state: false },
      //   { seconds: '35', title: '第三小节', state: false },
      //   { seconds: '55', title: '第四小节', state: false },
      //   { seconds: '60', title: '第五小节', state: false },
      //   { seconds: '65', title: '第刘小节', state: false }
      // ],
      //videoUrl: 'https://i.cdn.quyixian.com/BookRes/2b39c45d-886f-4a01-b7eb-1b9926fdb44a/66f7409b-09a4-4502-be58-1a2c154dcd97.mp4',
      //当前播放信息
      curr: {},
      wxCheck: 0, //企业微信验证（默认为 0开启） 0、开启  1、关闭
      province: "未知省份",
    };
  },
  watch: {
    userPower: {
      handler(newkey, oldkey) {
        if (newkey == false) {
          this.getCard();
        }
      },
    },
  },
  mounted() {
    // this.$wechat.getLocation().then((res) => {
    //   console.log(res)
    // })
    //判断参数是否有值
    if (this.bid != undefined && this.bid != "") {
      if (this.code && this.state == "status") {
        // this.location()
        this.getPower();
        //定时器，检测
        // 3.23不验证
        // let that = this
        // setInterval(function () {
        //   // console.log(that.userPower)
        //   // console.log(that.wxCheck)
        //   if (that.userPower == false && that.wxCheck == 0) {
        //     that.cardShow = true
        //   }
        // }, 5000)
      } else {
        wx(this.baseHref); //静默授权
      }
    } else {
      this.loading = false;
      this.loadingText = "解析URL出错啦...";
      this.loadingExce = true;
    }
  },
  methods: {
    //播放当前视频   播放视频地址未改
    playerCurr(id, bid, fid, url, nav, levels) {
      this.curr = {
        id: id,
        nav: nav,
        url: url,
        level: levels,
      };
      this.playState = true;

      //1图书 2目录
      let params = {
        bid: bid,
        fid: fid,
        rid: id,
        source: 1,
      };
      let header = {
        qyx_user_id: this.userid,
      };
      this.$api.mary.vResCount(params, header);
    },
    //根据图书获取全部目录资源
    getResBook() {
      this.loading = false;
      let params = {
        bid: this.bid,
      };
      let header = {
        qyx_user_id: this.userid,
      };
      this.$api.mary.getvbook(params, header).then((res) => {
        let { tag, data, message } = res;
        if (tag == 1) {
          this.loading = true;
          this.book = data;
          this.wxCheck = data.wxCheck;
          //console.log(data)
          document.title = data.bookName;
        } else {
          this.loading = false;
          this.loadingText = "获取数据失败啦...!";
          Dialog({
            title: "获取数据失败",
            message: message,
          });
        }
      });
    },
    //获取用户企业微信授权信息
    getPower() {
      let params = {
        code: this.code,
        state: this.state,
        bookId: this.bid,
      };
      this.$api.mary.getWxLogin(params).then((res) => {
        //console.log(res)
        let { tag, data, message } = res;
        if (tag == 1) {
          this.userid = data.qyx_user_id;
          //userid 作为授权判断
          //console.log('user: ' + data.external_userid)
          //3.23不验证
          // if (data.external_userid) {
          //   this.userPower = true
          // } else {
          //   this.userPower = false
          // }
          //获取资源
          this.getResBook();
          //修正地址栏URL
          history.replaceState(null, null, this.baseUrl);
        } else {
          Dialog({
            title: "认证失败",
            message: message,
            confirmButtonText: "刷新页面",
          }).then(() => {
            window.location.replace(this.baseUrl);
          });
        }
      });
    },
    //获取企业微信名片
    getCard() {
      let params = {
        bookId: this.bid,
        province: "",
      };
      this.$api.mary.getAddContactWay(params).then((res) => {
        let { tag, data, message } = res;
        if (tag == 1) {
          this.card = data.qr_code;
        } else {
          Dialog({
            title: "获取名片失败",
            message: message,
            confirmButtonText: "刷新页面",
          }).then(() => {
            window.location.replace(this.baseUrl);
          });
        }
      });
    },
    //获取省份
    location() {
      this.$wechat.getLocation().then((res) => {
        let { latitude, longitude, errMsg } = res;
        if (errMsg == "getLocation:ok") {
          this.$api.mary
            .getWxAddres({
              location: latitude + "," + longitude,
            })
            .then((res) => {
              let { tag, data, message } = res;
              //console.log(res)
              if (tag == 1 && data.message == "query ok") {
                this.province = data.result.address_component.province;
              }
            });
        }
      });
      //确保弹出后定位信息后再执行
      setTimeout(() => {
        this.getPower();
      }, 4500);
    },
  },
};
</script>
